// ! DO NOT REMOVE !
.ebat,
.materiaux {
  .picture {
    &--static {
      .image {
        position: static;
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;

        &__wrapper {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
