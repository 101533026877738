.rs-drawer {
  &-wrapper {
    top: 0;
    bottom: 0;
    height: auto;
  }

  &-header {
    display: block;
    padding: 0;

    + .rs-drawer-body {
      height: auto;
    }
  }

  &-body {
    padding: 0;
  }
}
