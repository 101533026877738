.block-navlink {
  color: inherit;
  text-decoration: none;
  font: {
    size: inherit;
    weight: inherit;
  }

  // For dotdotdot
  div {
    display: inline;
    width: auto;
  }
}
