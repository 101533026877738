.rs-pagination {
  &-group {
    display: block;
    margin: {
      top: 30px;
    }

    .rs-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 10px;
    }
  }

  .rs-pagination {
    &-btn {
      min-width: auto;
      box-sizing: content-box;
      padding: 0 5px;
      border: 0;
      margin: 0;
      background-color: transparent;
      color: var(--secondary-medium);
      font: {
        size: rem(16px);
        weight: var(--medium-font-weight);
      }

      &:hover,
      &-active {
        color: var(--primary-color);
      }

      &-disabled {
        color: var(--secondary-medium);
        cursor: default;
        pointer-events: none;
        margin: {
          top: 10px;
        }
      }

      &:first-child,
      &:last-child {
        display: flex;
        width: 35px;
        height: 35px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: 1px solid var(--secondary-color);
        border-radius: 50%;
        color: var(--secondary-color);
        margin: {
          top: 0;
        }

        &:hover {
          background-color: var(--secondary-color);

          .rs-pagination {
            &-symbol {
              filter: invert(97%) sepia(97%) saturate(12%) hue-rotate(166deg)
                brightness(103%) contrast(100%);
            }
          }
        }

        .rs-icon {
          display: none;
        }

        .rs-pagination {
          &-symbol {
            filter: invert(13%) sepia(5%) saturate(213%) hue-rotate(131deg)
              brightness(94%) contrast(86%);
            background: {
              position: center;
              repeat: no-repeat;
              size: 7px 12px;
            }
          }
        }

        &.rs-pagination-btn {
          &-disabled {
            opacity: 0.2;
          }
        }
      }

      &:first-child {
        margin: {
          right: 15px;
        }

        .rs-pagination {
          &-symbol {
            background: {
              image: url('../../../assets/icons/chevron-left.svg');
            }
          }
        }
      }

      &:last-child {
        margin: {
          left: 15px;
        }

        .rs-pagination {
          &-symbol {
            background: {
              image: url('../../../assets/icons/chevron-right.svg');
            }
          }
        }
      }
    }

    &-symbol {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}
