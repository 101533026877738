@import '../abstract/variables';
@import '../abstract/breakpoints';

:root {
  // Colors
  --default-color: #000000;
  //
  --primary-color: #0020ff;
  --primary-light: #d9ddff;
  //
  --secondary-color: #2d2e2e;
  --secondary-medium: #919395;
  --secondary-light: #d4d7de;
  --secondary-x-light: #f4f8fc;
  //
  --color-1: #45e59b;
  --color-2: #ff9862;
  --color-3: #58b9eb;
  --color-4: #e50019;
  --color-5: #ff7e60;
  --color-6: #9f00ff;
  --color-7: #ffffb5;
  --color-8: #434343;
  --color-9: #fff2ac;
  --color-10: #f7c821;
  //
  --black: #000000;
  --black-apha-99: #00000099;
  --black-alpha-60: #00000060;
  --black-alpha-0: #00000000;
  --white: #ffffff;
  --white-alpha-80: #ffffff80;
  --grey-1-alpha-40: #44444440;
  --grey-1-alpha-0: #44444400;
  --primary-color-alpha-20: #0020ff10;

  // Color utils
  --danger: var(--color-4);
  --color-danger: var(--danger);

  // Durations
  --duration: #{$duration}ms;
  --duration-slow: #{$duration * 2}ms;

  // Fonts
  --default-font-family: Lato, Avenir, 'Adobe Heiti Std', 'Segoe UI',
    'Trebuchet MS', sans‑serif;
  //
  --primary-font-family: Montserrat, 'DejaVu Sans', Verdana, sans‑serif;
  --secondary-font-family: Ubuntu, 'Helvetica Neue', 'PT Sans', Tahoma,
    sans‑serif;

  // Font sizes
  --x-small-font-size: 12px;
  --small-font-size: 14px;
  --medium-font-size: 16px;
  --large-font-size: 18px;
  --default-font-size: var(--small-font-size);

  // Font weights
  --default-font-weight: var(--normal-font-weight);
  //
  --light-font-weight: #{$light-font-weight};
  --normal-font-weight: #{$normal-font-weight};
  --medium-font-weight: #{$medium-font-weight};
  --semi-bold-font-weight: #{$semi-bold-font-weight};
  --bold-font-weight: #{$bold-font-weight};
  --extra-bold-font-weight: #{$extra-bold-font-weight};

  // Letter spacings
  --default-letter-spacing: 0;
  //
  --small-letter-spacing: 1px;

  // Line heights
  --small-line-height: 1;
  --medium-line-height: 1.6;
  --large-line-height: 1.8;
  //
  --default-line-height: var(--small-line-height);

  // Easing
  --ease: ease-in-out;
  --ease-in: ease-in;
  --ease-out: ease-out;

  //  Transition durations
  --default-duration: 0.2s;
}
