.rs-picker-daterange {
  &-menu {
    .rs-calendar {
      height: auto;
      min-height: 0;
      padding-bottom: 0;

      &:first-child {
        border-right: 0;
      }

      &-header {
        width: auto;

        &-title {
          color: var(--default-color);
          font-size: 14px;
          pointer-events: none;
        }

        &-backward,
        &-forward {
          .rs-icon {
            fill: var(--default-color);
            font-size: 18px;
          }

          &:focus {
            background-color: transparent;
          }
        }
      }

      .rs-calendar-table-cell {
        padding: 0;
        border-top: solid var(--secondary-light) thin;
        border-left: solid var(--secondary-light) thin;

        &:last-of-type {
          border-right: solid var(--secondary-light) thin;
        }

        &::before {
          display: none;
        }

        .rs-calendar-table-cell-content {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding: 0;
          border-radius: 0;
          color: var(--default-color);

          &:hover {
            background-color: var(--primary-light);
            color: var(--default-color);
          }
        }
      }

      .rs-calendar-table-cell-is-today {
        .rs-calendar-table-cell-content {
          box-shadow: none;
        }
      }

      .rs-calendar-table-cell-in-range {
        .rs-calendar-table-cell-content {
          background-color: var(--primary-light);
          color: var(--default-color);
        }
      }

      .rs-calendar-table-cell-selected {
        .rs-calendar-table-cell-content {
          background-color: var(--primary-color);
          color: var(--white);

          &:hover {
            background-color: var(--primary-color);
            color: var(--white);
          }
        }
      }

      .rs-calendar-table-cell-un-same-month {
        .rs-calendar-table-cell-content {
          background-color: var(--white);
          color: var(--secondary-light);

          &:hover {
            background-color: var(--white);
            color: var(--secondary-light);
          }
        }
      }

      .rs-calendar-table-row:last-of-type {
        .rs-calendar-table-cell {
          border-bottom: solid var(--secondary-light) thin;
        }
      }

      .rs-calendar-table-header-row {
        .rs-calendar-table-cell {
          border: 0;
        }
      }
    }

    .rs-calendar-view {
      padding-bottom: 30px;
    }

    .rs-picker-toolbar {
      display: flex;
      align-items: flex-end;
      border-top: solid var(--secondary-x-light) thin;
      margin-top: 0;

      .rs-picker-toolbar-ranges,
      .rs-picker-toolbar-right {
        .rs-btn {
          padding: 13px 20px;
          border-radius: 9999px;
          font-size: 14px;
          line-height: 16.09px;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }

      .rs-picker-toolbar-ranges {
        .rs-btn {
          border: solid var(--secondary-light) thin;
          margin-right: 12px;
          color: var(--secondary-medium);

          &:hover,
          &:focus {
            border: solid var(--primary-color) thin;
            color: var(--primary-color);
          }
        }
      }

      .rs-picker-toolbar-right {
        .rs-btn {
          border: 0;
          background-color: var(--primary-color);
          color: var(--white);
        }
      }
    }
  }

  &-header {
    display: none;
  }

  &-calendar-single {
    display: flex;
    height: auto;
  }

  &-calendar-group {
    display: flex;
    height: auto;
  }
}
