@import '../../abstract/breakpoints';

.rs-modal {
  max-width: 100%;
  margin: {
    top: 0;
  }

  &-sm {
    width: 100%;
  }

  @include media('>=tablet') {
    &-sm {
      width: 600px;
    }

    &-full {
      width: 100%;
    }
  }

  &-visible {
    .rs-modal-content {
      overflow-y: visible;
    }
  }

  &-wrapper {
    display: flex;

    @include media('>=tablet') {
      align-items: center;
    }
  }

  &-content {
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    flex-flow: column;
    padding: 75px 20px;
    border: 1px solid var(--black);
    background-color: var(--white);
    border-radius: 0;
    overflow-y: auto;

    @include media('>=tablet') {
      height: auto;
      max-height: 90vh;
      padding: 75px 40px;
    }
  }

  &-header {
    .rs-modal-header-close {
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: pointer;
      transition: transform var(--duration) var(--ease);

      @include media('>=tablet') {
        top: 40px;
        right: 40px;
      }

      .rs-icon {
        display: block;
        width: 20px;
        height: 20px;
        color: var(--secondary-color);
      }

      &::before {
        display: none;
      }

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  &-body {
    padding: {
      bottom: 0;
    }
    margin: {
      top: 0;
    }
  }

  &-title {
    font: {
      size: rem(22px);
      weight: var(--medium-font-weight);
    }
    margin: {
      top: 0;
      bottom: 30px;
    }
    white-space: normal;
  }
}
