@import '../abstract/variables';

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  color: var(--default-color);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font: {
    family: var(--default-font-family);
    size: rem(14px);
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

[id='root'] {
  position: relative;
  z-index: 1;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: stretch;
}

.main {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

input:focus-visible,
button:focus-visible {
  outline: transparent auto 0;
}
