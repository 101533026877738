@import '../../../styles/abstract/functions';
@import '../../../styles/abstract/breakpoints';

.custom-toastify {
  .Toastify & .Toastify {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('>=tablet') {
      flex-direction: row;
    }

    &__progress-bar {
      height: 4px;
    }

    &__progress-bar {
      &--default {
        background: var(--white);
      }
    }

    &__button-container {
      display: flex;
      flex-direction: row;
    }

    &__toast {
      padding: 20px;
      margin-bottom: 0;
      border-radius: 0;

      &::before,
      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        height: 4px;
        background-color: var(--white);
        content: '\0020';
        opacity: 0.5;
      }

      &-body {
        padding: 0;
      }

      &-container {
        &--bottom-left {
          width: auto;
          min-height: 70px;
          padding: 0;
        }
      }

      &--warning {
        margin-bottom: 0;
        background-color: var(--color-danger);
      }

      &--default {
        background-color: var(--primary-color);
      }

      &--close {
        right: 20px;
        width: 15px;
        height: 15px;
        margin: auto;
        margin-left: 20px;
        transition: transform var(--duration) ease-out;

        .svg-icon {
          fill: var(--white);
        }
      }

      &--button {
        min-width: 60px;
        padding: 0;
        margin-left: 10px;
        font-size: rem(10px);
      }

      &--content {
        margin-bottom: 15px;
        color: var(--white);
        font: {
          family: var(--secondary-font-family);
          size: rem(12px);
        }

        @include media('>=tablet') {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}
