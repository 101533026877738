@import '../../../styles/abstract/variables';

.button {
  // All vars MUST to be declared in the project
  // Properties
  display: var(--button-display);
  overflow: var(--button-overflow);
  width: var(--button-width);
  min-width: var(--button-min-width);
  max-width: var(--button-max-width);
  height: var(--button-height);
  min-height: var(--button-min-height);
  max-height: var(--button-max-height);
  box-sizing: var(--button-box-sizing);
  flex-direction: var(--button-flex-direction);
  flex-wrap: var(--button-flex-wrap);
  align-items: var(--button-align-items);
  justify-content: var(--button-justify-content);
  padding: var(--button-padding-top) var(--button-padding-right)
    var(--button-padding-bottom) var(--button-padding-left);
  border: var(--button-border);
  appearance: none;
  background-color: var(--button-background-color);
  border-radius: var(--button-border-radius);
  box-shadow: var(--button-box-shadow);
  color: var(--button-color);
  cursor: var(--button-cursor);
  font-family: var(--secondary-font-family);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  gap: var(--button-gap);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  opacity: var(--button-opacity);
  text-align: var(--button-text-align);
  text-decoration: var(--button-text-decoration);
  text-overflow: var(--button-text-overflow);
  text-transform: var(--button-text-transform);
  white-space: var(--button-white-space);

  &__icon {
    width: var(--button-icon-width);
    height: var(--button-icon-height);
    fill: var(--button-icon-fill);
  }
}
