.rs-picker-select {
  &-menu {
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: none;

    &-item {
      box-sizing: border-box;
    }
  }

  &-menu-items {
    padding: 6px 12px 12px;
    margin-bottom: 15px;
    overflow-x: clip;
  }

  .rs-picker {
    &-toggle {
      &-read-only {
        opacity: 0;
        pointer-events: none;
      }

      &-value {
        font-size: 14px;
      }

      &-placeholder {
        color: var(--default-color);
        font-size: 14px;
      }

      &-caret {
        width: 10px !important;
        height: 10px;
        transition: transform 0.3s ease;
      }
    }
  }
}
