.rs-picker-date {
  &-menu {
    .rs-calendar {
      width: 300px;

      * {
        box-sizing: border-box;
      }

      &-table-cell-selected {
        .rs-calendar-table-cell-content {
          background-color: var(--primary-color);
        }
      }
    }
  }

  &-toolbar {
    display: none;
  }
}
