.rs-tag {
  color: var(--secondary-color);

  &-text {
    font-size: rem(14px);
  }

  &-md {
    font-size: rem(14px);
  }

  &-icon-close {
    cursor: pointer;
  }
}

.rs-tag-md .rs-tag-icon-close {
  padding-top: 0;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 700;

  &:hover {
    color: var(--primary-color);
  }
}

.rs-tag-closable.rs-tag-md {
  padding-right: 0;
}

.rs-tag + .rs-tag {
  margin-left: 0;
}

.rs-tag-icon-close {
  position: relative;
  padding: 0;
  vertical-align: top;
}
