.rs-picker {
  &-default {
    display: block;

    &:not(.rs-picker-disabled) {
      .rs-picker-toggle {
        &:hover,
        &-active {
          border-color: var(--secondary-light);
        }
      }
    }

    &.rs-picker-has-value {
      .rs-picker-toggle-value {
        color: var(--secondary-color);
      }
    }

    .rs-picker-toggle {
      display: flex;
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      align-items: center;
      border: 1px solid var(--secondary-light);
      background-color: var(--white);
      border-radius: 0;
      box-shadow: none;

      &-active {
        border: 1px solid var(--secondary-light);
      }

      &-value,
      &-placeholder {
        font: {
          family: var(--default-font-family);
          size: var(--x-small-font-size);
        }
      }

      &-placeholder {
        color: var(--secondary-medium);
      }

      &-textbox {
        box-sizing: border-box;
        border: 0;
        color: var(--secondary-color);
        padding: {
          top: 0;
          right: 20px;
          bottom: 0;
          left: 20px;
        }
        font: {
          family: var(--default-font-family);
          size: var(--x-small-font-size);
        }

        &::placeholder {
          color: var(--secondary-medium);
        }
      }

      &-read-only {
        opacity: 1;
      }

      &.rs-btn {
        padding: {
          top: 0;
          right: 20px;
          bottom: 0;
          left: 20px;
        }

        .rs-picker-toggle {
          &-clean,
          &-caret {
            position: absolute;
            top: 50%;
            padding: 0;
            margin: 0;
            transform: translateY(-50%);
          }

          &-clean {
            right: 55px;

            &,
            .rs-icon {
              width: 15px;
              height: 15px;
            }
          }

          &-caret {
            right: 20px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }

  &-menu {
    border: 1px solid var(--secondary-light);

    .rs-picker-search-bar-input:not(:hover),
    .rs-picker-search-bar {
      .rs-picker-search-bar-input:hover:not(:disabled) {
        border-color: var(--rs-border-primary);
        outline: 0;
      }

      .rs-picker-search-bar-search-icon {
        top: 22px;
        right: 25px;
        color: var(--default-color);
      }
    }

    .rs-picker-search-bar,
    .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled),
    .rs-picker-search-bar-input:not(:hover) {
      display: flex;
      height: 40px;
      border-radius: 0;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .rs-picker-select-menu-item-focus,
    .rs-picker-select-menu-item-active {
      background-color: var(--primary-light);
      color: var(--primary-color);
    }
  }

  &-select-menu-item {
    &:hover {
      background-color: var(--primary-light);
      color: var(--primary-color);
    }
  }
}
